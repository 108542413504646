import { useCallback } from 'react';
import _ from 'lodash';
import {
    useHistory,
} from 'react-router-dom';
import ErrorHandler from '../services/ErrorHandler';
import {
    UW_ERROR_CODE,
    FUNCTIONAL_ERROR_CODE,
    FUTURE_DATED_MTA_ERROR_CODE,
    RENEWAL_ERROR_CODE,
    VALIDATION_ERROR_CODE
} from '../constants/ErrorCodesConstants';

function useErrorHandler() {
    const history = useHistory();

    const resolveJobId = useCallback((error, referencedJobId) => {
        const jobIDFromWizardObj = _.get(error, 'gwInfo.params[0].jobID');
        const jobIDFromWizard = _.get(error, 'gwInfo.params[0]');
        const jobId = referencedJobId || jobIDFromWizardObj || jobIDFromWizard;
        return jobId && typeof jobId !== 'object' ? jobId : undefined;
    }, []);

    const handleFunctionalError = useCallback((error, referencedJobId) => {
        const jobId = resolveJobId(error, referencedJobId);
        const path = jobId
            ? `/contact-us?jobid=${jobId}`
            : '/contact-us';
        history.push(path);
    }, [history, resolveJobId]);

    const handleValidationError = useCallback((error, referencedJobId) => {
        const jobId = resolveJobId(error, referencedJobId);
        const path = jobId
            ? `/contact-us?jobid=${jobId}`
            : '/contact-us';
        history.push(path);
    }, [history, resolveJobId]);

    const handleTechnicalError = useCallback((error, referencedJobId) => {
        const jobId = resolveJobId(error, referencedJobId);
        const path = jobId
            ? `/service-unavailable?jobid=${jobId}`
            : '/service-unavailable';
        history.push(path);
    }, [history, resolveJobId]);

    const logError = useCallback((error, jobId) => {
        console.debug(`Time: ${new Date()}`);
        console.debug(`JobID: ${resolveJobId(error, jobId)}`);
        console.debug(error?.baseError);
        console.debug(error);
    }, [resolveJobId]);

    const handleFutureDatedMtaError = useCallback((error, referencedJobId) => {
        const jobId = resolveJobId(error, referencedJobId);
        history.push(`/contact-us?jobid=${jobId}&hasFutureMta=true`);
    }, [history, resolveJobId]);

    const handleRenewalError = useCallback((error, referencedJobId) => {
        const jobId = resolveJobId(error, referencedJobId);
        history.push(`/contact-us?policyNumber=${jobId}&showRenewalDesc=true`);
    }, [history, resolveJobId]);

    const handleError = useCallback((error, referencedJobId) => {
        logError(error);
        if (error?.appErrorCode === FUTURE_DATED_MTA_ERROR_CODE) {
            handleFutureDatedMtaError(error, referencedJobId);
        } else if (error?.appErrorCode === RENEWAL_ERROR_CODE) {
            handleRenewalError(error, referencedJobId);
        } else if (error?.appErrorCode
            && (error?.appErrorCode === UW_ERROR_CODE
                || error?.appErrorCode === FUNCTIONAL_ERROR_CODE)) {
            handleFunctionalError(error, referencedJobId);
        } else if (error?.appErrorCode === VALIDATION_ERROR_CODE) {
            handleValidationError(error, referencedJobId);
        } else {
            handleTechnicalError(error, referencedJobId);
        }
    }, [
        handleFunctionalError,
        handleTechnicalError,
        logError,
        handleFutureDatedMtaError,
        handleRenewalError,
        handleValidationError
    ]);

    const handleNotBlockingError = useCallback((error) => {
        logError(error);
    }, [logError]);

    const getErrorCode = useCallback((error) => {
        logError(error);
        return error?.appErrorCode;
    }, [logError]);

    const getDisplayableErrorMessage = useCallback((error) => {
        return ErrorHandler.getDisplayableErrorMessage(error);
    }, []);

    return {
        handleError,
        handleUWError: handleFunctionalError,
        handleNotBlockingError,
        getDisplayableErrorMessage,
        getErrorCode
    };
}

export default useErrorHandler;
