import SectionComponentWithTitle from './SectionComponentWithTitle/SectionComponentWithTitle';
import PaymentPremiumInfoBox from './PaymentPremiumInfoBox/PaymentPremiumInfoBox';
import NfumCostInfoBox from './NfumCostInfoBox/NfumCostInfoBox';
import SpecificError from './SpecificError/SpecificError';
import NfumFieldLabel from './NfumFieldLabel/NfumFieldLabel';
import NfumInputField from './NfumInputField/NfumInputField';
import NfumDropdownSelectField from './NfumDropdownSelectField/NfumDropdownSelectField';
import NfumRadioButtonField from './NfumRadioButtonField/NfumRadioButtonField';
import NfumRadioButtonCardField from './NfumRadioButtonCardField/NfumRadioButtonCardField';
import NfumTypeaheadMultiSelectField from './NfumTypeaheadMultiSelectField/NfumTypeaheadMultiSelectField';
import NfumCheckboxField from './NfumCheckboxField/NfumCheckboxField';
import NfumDateField from './NfumDateField/NfumDateField';
import NfumStepperField from './NfumStepperField/NfumStepperField';
import NfumSwitchField from './NfumSwitchField/NfumSwitchField';
import NfumTextAreaField from './NfumTextAreaField/NfumTextAreaField';
import NfumAccordionHeader from './NfumAccordionHeader/NfumAccordionHeader';
import DocumentsLinksComponent from './DocumentsLinks/DocumentsLinksComponent/DocumentsLinksComponent';
import PaymentUnsuccessfulMessage from './PaymentUnsuccessfulMessage/PaymentUnsuccessfulMessage';
import CallOrContactInfo from './CallOrContactInfo/CallOrContactInfo';
import ReturnToDashboardModal from './ReturnToDashboardModal/ReturnToDashboardModal';
import NfumConfirmModal from './NfumConfirmModal/NfumConfirmModal';
import NfumCurrencyField from './NfumCurrencyField/NfumCurrencyField';
import NfumLoader from './NfumLoader/NfumLoader';
import NfumLoaderButton from './NfumLoaderButton/NfumLoaderButton';
import NfumInactiveModal from './NfumInactiveModal/NfumInactiveModal';
import NfumTermsAndConditions from './NfumTermsAndConditions/NfumTermsAndConditions';
import NfumSpinner from './NfumSpinner/NfumSpinner';

const platformComponentMap = {
    sectionComponentWithTitle: { component: 'sectionComponentWithTitle' },
    SectionComponentWithTitle: { component: 'SectionComponentWithTitle' },
    paymentPremiumInfoBox: { component: 'paymentPremiumInfoBox' },
    PaymentPremiumInfoBox: { component: 'PaymentPremiumInfoBox' },
    nfumCostInfoBox: { component: 'nfumCostInfoBox' },
    NfumCostInfoBox: { component: 'NfumCostInfoBox' },
    specificError: { component: 'specificError' },
    SpecificError: { component: 'SpecificError' },
    NfumFieldLabel: { component: 'NfumFieldLabel' },
    nfumFieldLabel: { component: 'nfumFieldLabel' },
    NfumInputField: { component: 'NfumInputField' },
    nfumInputField: { component: 'nfumInputField' },
    NfumDropdownSelectField: { component: 'NfumDropdownSelectField' },
    nfumDropdownSelectField: { component: 'nfumDropdownSelectField' },
    NfumRadioButtonField: { component: 'NfumRadioButtonField' },
    nfumRadioButtonField: { component: 'nfumRadioButtonField' },
    NfumRadioButtonCardField: { component: 'NfumRadioButtonCardField' },
    nfumRadioButtonCardField: { component: 'nfumRadioButtonCardField' },
    NfumTypeaheadMultiSelectField: { component: 'NfumTypeaheadMultiSelectField' },
    nfumTypeaheadMultiSelectField: { component: 'nfumTypeaheadMultiSelectField' },
    NfumCheckboxField: { component: 'NfumCheckboxField' },
    nfumCheckboxField: { component: 'nfumCheckboxField' },
    NfumDateField: { component: 'NfumDateField' },
    nfumDateField: { component: 'nfumDateField' },
    NfumStepperField: { component: 'NfumStepperField' },
    nfumStepperField: { component: 'nfumStepperField' },
    NfumSwitchField: { component: 'NfumSwitchField' },
    nfumSwitchField: { component: 'nfumSwitchField' },
    NfumTextAreaField: { component: 'NfumTextAreaField' },
    nfumTextAreaField: { component: 'nfumTextAreaField' },
    NfumAccordionHeader: { component: 'NfumAccordionHeader' },
    documentsLinksComponent: { component: 'documentsLinksComponent' },
    DocumentsLinksComponent: { component: 'DocumentsLinksComponent' },
    paymentUnsuccessfulMessage: { component: 'paymentUnsuccessfulMessage' },
    PaymentUnsuccessfulMessage: { component: 'PaymentUnsuccessfulMessage' },
    callOrContactInfo: { component: 'callOrContactInfo' },
    CallOrContactInfo: { component: 'CallOrContactInfo' },
    NfumCurrencyField: { component: 'NfumCurrencyField' },
    nfumCurrencyField: { component: 'nfumCurrencyField' },
    nfumLoader: { component: 'nfumLoader' },
    NfumLoader: { component: 'NfumLoader' },
    nfumLoaderButton: { component: 'nfumLoaderButton' },
    NfumLoaderButton: { component: 'NfumLoaderButton' },
    nfumTermsAndConditions: { component: 'nfumTermsAndConditions' },
    NfumTermsAndConditions: { component: 'NfumTermsAndConditions' },
    nfumSpinner: { component: 'nfumSpinner' },
    NfumSpinner: { component: 'NfumSpinner' }
};

const platformComponents = {
    sectionComponentWithTitle: SectionComponentWithTitle,
    SectionComponentWithTitle,
    paymentPremiumInfoBox: PaymentPremiumInfoBox,
    PaymentPremiumInfoBox,
    nfumCostInfoBox: NfumCostInfoBox,
    NfumCostInfoBox,
    specificError: SpecificError,
    SpecificError,
    NfumFieldLabel,
    nfumFieldLabel: NfumFieldLabel,
    NfumInputField,
    nfumInputField: NfumInputField,
    NfumDropdownSelectField,
    nfumDropdownSelectField: NfumDropdownSelectField,
    NfumRadioButtonField,
    nfumRadioButtonField: NfumRadioButtonField,
    NfumRadioButtonCardField,
    nfumRadioButtonCardField: NfumRadioButtonCardField,
    NfumTypeaheadMultiSelectField,
    nfumTypeaheadMultiSelectField: NfumTypeaheadMultiSelectField,
    NfumCheckboxField,
    nfumCheckboxField: NfumCheckboxField,
    NfumDateField,
    nfumDateField: NfumDateField,
    NfumStepperField,
    nfumStepperField: NfumStepperField,
    NfumSwitchField,
    nfumSwitchField: NfumSwitchField,
    NfumTextAreaField,
    nfumTextAreaField: NfumTextAreaField,
    NfumAccordionHeader,
    documentsLinksComponent: DocumentsLinksComponent,
    DocumentsLinksComponent,
    paymentUnsuccessfulMessage: PaymentUnsuccessfulMessage,
    PaymentUnsuccessfulMessage,
    callOrContactInfo: CallOrContactInfo,
    CallOrContactInfo,
    NfumCurrencyField,
    nfumCurrencyField: NfumCurrencyField,
    nfumLoader: NfumLoader,
    NfumLoader,
    nfumLoaderButton: NfumLoaderButton,
    NfumLoaderButton,
    nfumTermsAndConditions: NfumTermsAndConditions,
    NfumTermsAndConditions,
    nfumSpinner: NfumSpinner,
    NfumSpinner
};

export {
    SectionComponentWithTitle,
    PaymentPremiumInfoBox,
    NfumCostInfoBox,
    SpecificError,
    NfumFieldLabel,
    NfumInputField,
    NfumDropdownSelectField,
    NfumRadioButtonField,
    NfumRadioButtonCardField,
    NfumTypeaheadMultiSelectField,
    NfumCheckboxField,
    NfumDateField,
    NfumStepperField,
    NfumSwitchField,
    NfumTextAreaField,
    NfumAccordionHeader,
    PaymentUnsuccessfulMessage,
    CallOrContactInfo,
    ReturnToDashboardModal,
    NfumConfirmModal,
    NfumLoader,
    NfumLoaderButton,
    NfumCurrencyField,
    NfumInactiveModal,
    NfumTermsAndConditions,
    NfumSpinner,
    // for Jutro
    platformComponentMap,
    platformComponents
};
