import { defineMessages } from 'react-intl';

export default defineMessages({
    renewMyPolicy: {
        id: 'renewal.ngh.views.renewal-summary.renewMyPolicy',
        defaultMessage: 'Renew my policy'
    },
    needToMakeChange: {
        id: 'renewal.ngh.views.renewal-summary.needToMakeChange',
        defaultMessage: 'Need to make a change?'
    },
    ddReviewPolicyInfo: {
        id: 'renewal.ngh.views.renewal-summary.ddReviewPolicyInfo',
        defaultMessage: 'Your policy will automatically renew on '
    },
    ddReviewPolicyMessage: {
        id: 'renewal.ngh.views.renewal-summary.ddReviewPolicyMessage',
        defaultMessage: '. As you pay by Direct Debit, once you have reviewed your policy information in the sections below, there’s nothing you need to do. If you need to make a change, please get in touch.'
    },
    cashReviewPolicyInfo: {
        id: 'renewal.ngh.views.renewal-summary.cashReviewPolicyInfo',
        defaultMessage: 'Please review your policy information in the sections below and if you want to change to monthly payments, or if anything has changed, please get in touch.'
    },
    policyDetails: {
        id: 'renewal.ngh.views.renewal-summary.policyDetails',
        defaultMessage: 'Policy Details'
    },
    buildings: {
        id: 'renewal.ngh.views.renewal-summary.buildings',
        defaultMessage: 'Buildings'
    },
    contents: {
        id: 'renewal.ngh.views.renewal-summary.contents',
        defaultMessage: 'Contents'
    },
    valuables: {
        id: 'renewal.ngh.views.renewal-summary.valuables',
        defaultMessage: 'Valuables'
    },
    bicycles: {
        id: 'renewal.ngh.views.renewal-summary.bicycles',
        defaultMessage: 'Bicycles'
    },
    additionalCovers: {
        id: 'renewal.ngh.views.renewal-summary.additionalCovers',
        defaultMessage: 'Additional Insurances'
    },
    caravan: {
        id: 'renewal.ngh.views.renewal-summary.caravan',
        defaultMessage: 'Caravan'
    },
    caravanInfo: {
        id: 'renewal.ngh.views.renewal-summary.caravanInfo',
        defaultMessage: 'A summary of your Caravan cover and the information you have provided are available in '
    },
    yourQuoteDocumentation: {
        id: 'renewal.ngh.views.renewal-summary.yourQuoteDocumentation',
        defaultMessage: 'your quote documentation'
    },
    takeTimeToReadCarefully: {
        id: 'renewal.ngh.views.renewal-summary.takeTimeToReadCarefully',
        defaultMessage: '. Please take the time to read this carefully before proceeding.'
    },
    catDog: {
        id: 'renewal.ngh.views.renewal-summary.catDog',
        defaultMessage: 'Cat/Dog'
    },
    dogAndCatInfo: {
        id: 'renewal.ngh.views.renewal-summary.dogAndCatInfo',
        defaultMessage: 'A summary of your Cat/Dog cover and the information you have provided are available in '
    },
    travel: {
        id: 'renewal.ngh.views.renewal-summary.travel',
        defaultMessage: 'Travel'
    },
    reviewTravelCoverages: {
        id: 'renewal.ngh.views.renewal-summary.reviewTravelCoverages',
        defaultMessage: 'Review travel coverages'
    },
    travelInfo: {
        id: 'renewal.ngh.views.renewal-summary.travelInfo',
        defaultMessage: 'A summary of your Travel cover and the information you have provided are available in '
    },
    personalAccident: {
        id: 'renewal.ngh.views.renewal-summary.personalAccident',
        defaultMessage: 'Personal Accident'
    },
    paInfo: {
        id: 'renewal.ngh.views.renewal-summary.paInfo',
        defaultMessage: 'A summary of your Personal accident cover and the information you have provided are available in '
    },
    yourDocuments: {
        id: 'renewal.ngh.views.renewal-summary.yourDocuments',
        defaultMessage: 'Your documents'
    },
    documentsInfoDesc: {
        id: 'endorsement.ngh.views.renewal-summary.documentsInfoDesc',
        defaultMessage: 'Please take the time to read these important documents before you complete your purchase to ensure the policy is right for you. You \'ll also find details on how to cancel and how to make a claim'
    },
    paymentSchedule: {
        id: 'renewal.ngh.views.renewal-summary.paymentSchedule',
        defaultMessage: 'Payment schedule'
    },
    reviewDetails: {
        id: 'renewal.ngh.views.renewal-summary.reviewDetails',
        defaultMessage: 'Open'
    },
    reviewCover: {
        id: 'renewal.ngh.views.renewal-summary.reviewCover',
        defaultMessage: 'Open'
    },
    readDocumentsBeforeRenew: {
        id: 'renewal.ngh.views.renewal-summary.readDocumentsBeforeRenew',
        defaultMessage: 'Please take time to read these important documents before you renew'
    },
    specialConditionsTitle: {
        id: 'renewal.ngh.views.renewal-summary.specialConditionsTitle',
        defaultMessage: 'Special conditions'
    },
    specialConditionsBodyText1: {
        id: 'renewal.ngh.views.renewal-summary.specialConditionsBodyText1',
        defaultMessage: 'Please note specific conditions apply to your policy. Please check your '
    },
    policyScheduleLink: {
        id: 'renewal.ngh.views.renewal-summary.policyScheduleLink',
        defaultMessage: 'schedule'
    },
    specialConditionsBodyText5: {
        id: 'renewal.ngh.views.renewal-summary.specialConditionsBodyText2',
        defaultMessage: ' for full details.'
    },
    addValidationReferalMessage1: {
        id: 'quoteandbind.ngh.views.renewal-summary.addValidationReferalMessage1',
        defaultMessage: 'We can only setup payments online that are both in your name and have the same billing address as the property you are looking to insure.'
    },
    addValidationReferalMessage2: {
        id: 'quoteandbind.ngh.views.renewal-summary.addValidationReferalMessage2',
        defaultMessage: 'If you want to use a different method of payment that is in your name and matches the address you want to insure then click \'I confirm\' and proceed. Alternatively, you can get in touch using the options below'
    },
    renewalBillingAddress: {
        id: 'quoteandbind.ngh.views.renewal-summary.billingAddress',
        defaultMessage: 'Billing address'
    },
    renewConfirmMessage: {
        id: 'quoteandbind.ngh.views.renewal-summary.confirmMessage',
        defaultMessage: 'Please confirm the following statements are correct:'
    },
    renewYourNameAddress: {
        id: 'quoteandbind.ngh.views.renew-summary.yourNameAddress',
        defaultMessage: 'The account you are paying from is in your name'
    },
    renewInsureBillingAddress: {
        id: 'quoteandbind.ngh.views.renew-summary.insureBillingAddress',
        defaultMessage: 'The billing address of this account is the address you are looking to insure'
    },
    readCarefully: {
        id: 'renewal.ngh.views.renewal-summary.readCarefully',
        defaultMessage: '. Please take the time to read this carefully before proceeding.'
    },
    contentsInfo: {
        id: 'renewal.ngh.views.renewal-summary.contentsInfo',
        defaultMessage: 'A summary of your Contents cover and the information you have provided are available in '
    },
    buildingsInfo: {
        id: 'renewal.ngh.views.renewal-summary.buildingsInfo',
        defaultMessage: 'A summary of your Buildings cover and the information you have provided are available in '
    },
    additionalCoversInfo: {
        id: 'renewal.ngh.views.renewal-summary.additionalCoversInfo',
        defaultMessage: 'A summary of your Additional Insurances and the information you have provided is available in '
    },
    customMessageHeader: {
        id: 'renewal.bespoke.views.renewal-summary.valuables.customMessageHeader',
        defaultMessage: 'About your valuables'
    },
    customMessageInfo: {
        id: 'renewal.ngh.views.renewal-summary.customMessageInfo',
        defaultMessage: 'Full details of your valuables are captured in your policy documents. '
    },
    customMessageDocumentation: {
        id: 'renewal.ngh.views.renewal-summary.customMessageDocumentation',
        defaultMessage: 'Click here to view them'
    },
    customMessageInfo1: {
        id: 'renewal.ngh.views.renewal-summary.customMessageInfo1',
        defaultMessage: 'If you would like to discuss your valuables with us please '
    },
    customMessageDocumentation1: {
        id: 'renewal.ngh.views.renewal-summary.customMessageDocumentation1',
        defaultMessage: 'contact your agency'
    },
    valuablesInfo: {
        id: 'renewal.ngh.views.renewal-summary.valuablesInfo',
        defaultMessage: 'A summary of your '
    },
    valuablesInfo1: {
        id: 'renewal.ngh.views.renewal-summary.valuablesInfo1',
        defaultMessage: 'Valuables'
    },
    valuablesInfo2: {
        id: 'renewal.ngh.views.renewal-summary.valuablesInfo2',
        defaultMessage: ' cover and the information you have provided are available in '
    },
    fineArts: {
        id: 'renewal.ngh.views.renewal-summary.fineArts',
        defaultMessage: 'Fine art and collections'
    },
    valuablesNewAcquisitionCoverHeader: {
        id: 'renewal.ngh.views.renewal-summary.valuablesNewAcquisitionCoverHeader',
        defaultMessage: 'Your policy includes New Acquisitions Cover'
    },
    valuablesNewAcquisitionCovercontent: {
        id: 'renewal.ngh.views.renewal-summary.valuablesNewAcquisitionCovercontent',
        defaultMessage: 'This cover is designed to give you peace of mind for Valuables you have just purchased, the most we will pay is up to 25% of your total valuable items replacement cost (please see your Policy Schedule for this amount), for a period of up to 60 days.'
    },
    fineArtNewAcquisitionCoverHeader: {
        id: 'renewal.ngh.views.renewal-summary.fineArtNewAcquisitionCoverHeader',
        defaultMessage: 'Your policy includes New Acquisitions Cover'
    },
    fineArtNewAcquisitionCovercontent: {
        id: 'renewal.ngh.views.renewal-summary.fineArtNewAcquisitionCovercontent',
        defaultMessage: 'This cover is designed to give you peace of mind for Fine Art & Collections you have just purchased, the most we will pay is up to 30% of your total Fine Art & Collections items replacement cost (please see your Policy Schedule for this amount), for a period of up to 60 days.'
    },
    significantChanges: {
        id: 'renewal.ngh.views.renewal-summary.significantChanges',
        defaultMessage: 'If any of the information you\'ve provided changes in future, you need to get in touch to make sure your policy is kept up to date. Failure to do so may affect your insurance.'
    },
    anythingChangesText: {
        id: 'renewal.ngh.views.renewal-summary.anythingChangesText',
        defaultMessage: 'If anything changes'
    },
    cancellationRightsText1: {
        id: 'renewal.ngh.views.renewal-summary.cancellationRightsText1',
        defaultMessage: 'You can cancel your policy at any other time by calling or writing to the contact details provided. There\'s no cancellation fee and you\'ll be refunded any remaining premium after the cancellation date, unless you’ve made a claim.'
    },
    cancellationRights: {
        id: 'renewal.ngh.views.renewal-summary.cancellationRights',
        defaultMessage: 'Your cancellation rights'
    },
    coolingPeriodText: {
        id: 'renewal.ngh.views.renewal-summary.coolingPeriodText',
        defaultMessage: 'If you choose not to go ahead with this policy, you may cancel within 14 days of receiving your policy documents. You\'ll be charged for the time the policy has been in force and your remaining premium will be refunded, as long as no claims have been made against the policy.'
    },
    coolingPeriod: {
        id: 'renewal.ngh.views.renewal-summary.coolingPeriod',
        defaultMessage: 'Cooling off period'
    },
    YourRightsAndConditions: {
        id: 'renewal.ngh.views.renewal-summary.Your rights and conditions',
        defaultMessage: 'Your rights and conditions'
    }
});
