import React, {
    useEffect
} from 'react';
import { withRouter } from 'react-router-dom';
import { Loader } from '@jutro/components';
import { useJobRetrieval } from 'nfum-portals-wizard-react';
import { getConfigValue } from '@jutro/config';

const returnToDCSDashboard = () => {
    const backToDashboardLink = getConfigValue('JUTRO_BACK_TO_DASHBOARD_PATH', '/');
    window.location.replace(backToDashboardLink);
};

function StartingPage() {
    const {
        jumpToRetrievedPolicyChange,
        jumpToRetrievedRenewal
    } = useJobRetrieval();

    useEffect(() => {
        const isPolicyChangeProcess = jumpToRetrievedPolicyChange();
        const isRenewalProcess = jumpToRetrievedRenewal();

        if (!isPolicyChangeProcess && !isRenewalProcess) {
            returnToDCSDashboard();
        }
    }, [jumpToRetrievedPolicyChange, jumpToRetrievedRenewal]);

    return <Loader loaded={false} />;
}

export default withRouter(StartingPage);
