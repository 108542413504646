import { defineMessages } from 'react-intl';

export default defineMessages({
    thankYou: {
        id: 'nfum.platform.NfumSpinner.thankYou',
        defaultMessage: 'Thank you for being patient.'
    },
    loadingYourDetails: {
        id: 'nfum.platform.NfumSpinner.loadingYourDetails',
        defaultMessage: 'We\'re just loading your details.'
    }
});
