import React from 'react';
import classNames from 'classnames';
import { Loader } from '@jutro/components';
import styles from './NfumLoader.module.scss';

function NfumLoader(props) {
    const { className } = props;
    const renderCustomLoader = () => {
        return (
            <div
                className={styles.loaderContainer}
                role="alert"
                aria-busy="true"
            >
                <span className={styles.loader} />
            </div>
        );
    };

    return (
        <Loader
            renderLoader={() => renderCustomLoader()}
            {...props}
            className={classNames(className, {
                [styles.nfumLoader]: true
            })}
        />
    );
}

export default NfumLoader;
