import React, { useContext, useCallback } from 'react';
import { ViewModelForm } from 'gw-portals-viewmodel-react';
import _ from 'lodash';
import { TranslatorContext } from '@jutro/locale';
import { useCurrency } from 'nfum-portals-utils-react';
import usePropertyTitle from '../../../hooks/usePropertyTitle';
import messages from './ContentsSummary.messages';
import metadata from './ContentsSummary.metadata.json5';
import styles from './ContentsSummary.module.scss';

function ContentsSummary(props) {
    const {
        homePropertiesData, homeLine, submissionVM
    } = props;
    const translator = useContext(TranslatorContext);
    const { getPropertyTitle } = usePropertyTitle();
    const { formatCurrency } = useCurrency();
    const contentsIds = Object.freeze({
        CONTENTS_ONLY: 'contentsOnly',
        HOM_BUILDINGS_COV: 'HOMBuildingsCov',
        HOM_CONTENTS_COV: 'HOMContentsCov',
        HOM_CONTENTS_COV_ACCIDENTAL_DAMAGE: 'HOMContentsCovAccidentalDamageCover',
        HOM_CONTENTS_COV_TENANTS_INTERIOR_DECORATION: 'HOMContentsCovTenantsInterior',
        HOM_CONTENTS_COV_VOLUNTARY_EXCESS: 'HOMContentsCovVoluntaryExcess',
        HOM_CONTENTS_COV_CONTENTS_LEVEL_LIMIT: 'HOMContentsCovContentsLevelLimit',
        HOM_CONTENTS_COV_CONTENTS_ESCAPE_OF_WATER_EXCESS_TOTAL: 'HOMContentsCovContentsEscapeOfWaterExcessTot',
        HOM_CONTENTS_COV_SUBSIDENCE_EXCESS: 'HOMContentsCovSubsidenceExcess',
        HOM_CONTENTS_COV_FLOOD_TOTAL_EXCESS: 'HOMContentsCovFloodTotalExcess',
        HOM_CONTENTS_AWAY_COV: 'HOMContentsAwayCov',
        HOM_CONTENTS_AWAY_COV_REQ_FOR_UNSPEC_BELONGINGS: 'HOMContentsAwayCoverReqForUnspecBelongings',
        HOM_CONTENTS_AWAY_COV_UNSPC_BELONGINGS_REPL_COST: 'HOMContentsAwayCovUnspecifiedBelongingsReplCost',
        HOM_CONTENTS_AWAY_COV_UNSPC_PEDAL_CYCLES_LIMIT: 'HOMContentsAwayCovUnspecifiedPedalCyclesLimit',
        HOM_CONTENTS_COV_ALL_OTHER_CLAIMS_EXCESS: 'HOMContentsCovAllOtherClaimsExcess',
        HOM_CONTENTS_COV_FLOOD_EXCESS_ADJUSTMENT: 'HOMContentsCovFloodExcessAdjustment',
        HOM_CONTENTS_COV_CONTENTS_ESCAPE_OF_WATER_EXCESS_ADJ: 'HOMContentsCovContentsEscapeOfWaterExcessAdj',
        HOM_CONTENTS_COV_SUBSIDENCE_EXCESS_ADJ: 'HOMContentsCovSubsidenceExcessAdj',
        HOM_CONTENTS_COV_SPECIFIED_VALUABLES_EXCESS_ADJUST: 'HOMContentsCovSpecifiedValuablesExcessAdjust',
        HOM_CONTENTS_COV_STORM_EXCESS_ADJUSTMENT: 'HOMContentsCovStormExcessAdjustment',
        HOM_CONTENTS_COV_FIRE_EXCESS_ADJUSTMENT: 'HOMContentsCovFireExcessAdjustment',
        HOM_CONTENTS_COV_THEFT_EXCESS_ADJUSTMENT: 'HOMContentsCovTheftExcessAdjustment',
        HOM_CONTENTS_COV_LEAKING_OIL_EXC_ADJ: 'HOMContentsCovLeakingOilExcAdj',
        HOM_CONTENTS_COV_FALLING_TREES_AND_BRANCHES_EXC_ADJ: 'HOMContentsCovFallingTreesAndBranchesExcAdj',
        HOM_CONTENTS_COV_FALLING_AERIALS_TURBINES_EXC_ADJ: 'HOMContentsCovFallingAerialsTurbinesExcAdj',
        HOM_CONTENTS_COV_CONTENT_COLLISION_VEH_EXCESS_ADJ: 'HOMContentsCovContentCollisionVehExcessAdj',
        HOM_CONTENTS_COV_CONTENTS_VANDALS_EXCESS_ADJUST: 'HOMContentsCovContentsVandalsExcessAdjust',
        HOM_CONTENTS_COV_RIOT_EXCESS_ADJ: 'HOMContentsCovRiotExcessAdj',
        HOM_CONTENTS_COV_AIRCRAFT_EXCESS_ADJ: 'HOMContentsCovAircraftExcessAdj',
        HOM_CONTENTS_COV_CONTENTS_PERSO_BELONG_REPLACEMENT_COST: 'HOMContentsCovContentsPersoBelongReplacementCost',
        HOM_CONTENTS_COV_OTHER_VOLUNTARY_EXCESS: 'HOMContentsCovOtherVoluntaryExcess',
        TENANT_INTERIOR_COST: 'HOMContentsCovTenantsReplacementCost',
        HOM_CONTENTS_COV_ACCIDENTAL_LOSS_DAMAGE_EXCESS: 'HOMContentsCovAccidentalLossOrDamageExcess'
    });
    const YES_KEY = 'Yes';
    const bespokeSustainedDmg = _.get(submissionVM.lobData.homeLine.value, 'isBespokeSustainedDmg');
    const bespokeFloodRisk = _.get(submissionVM.lobData.homeLine.value, 'isBespokeFloodRisk');

    const getTermValue = useCallback((cov, termId) => {
        return cov?.terms
            ?.find((term) => term.publicID === termId)
            ?.chosenTermValue;
    }, []);

    const getTermLimitValue = useCallback((cov, termPublicID, currSymbol = true) => {
        const chosenTermValueObj = cov?.terms
            ?.find((term) => term?.publicID === termPublicID);
        const chosenTermValue = chosenTermValueObj?.chosenTermValue;
        return currSymbol ? `£${chosenTermValue}` : chosenTermValue;
    }, []);

    const getContentLimitValue = useCallback((cov, termPublicID, currSymbol = true) => {
        const chosenTermValueObj = cov?.terms
            ?.find((term) => term?.publicID === termPublicID);
        const chosenTermValue = chosenTermValueObj?.chosenTermValue;
        if (chosenTermValue) {
            return currSymbol ? `£${chosenTermValue}` : chosenTermValue;
        }
        const contentsReplacementCostOverrid = homePropertiesData[0]
            ?.homhomePropertyConstruction.contentsReplacementCostOverrid;
        const formattedContReplCostOverride = formatCurrency(contentsReplacementCostOverrid);
        return formattedContReplCostOverride;
    }, [formatCurrency, homePropertiesData]);

    const displayContentLimit = useCallback((cov, termPublicID) => {
        const chosenTermValueObj = cov?.terms
            ?.find((term) => term?.publicID === termPublicID);
        const chosenTermValue = chosenTermValueObj?.chosenTermValue;
        const contentsReplacementCostOverrid = homePropertiesData[0]
            ?.homhomePropertyConstruction.contentsReplacementCostOverrid;
        if (chosenTermValue === undefined || contentsReplacementCostOverrid > chosenTermValue) {
            return false;
        }
        return true;
    }, [homePropertiesData]);

    const isAnyAdjustmentsCovTermValue = useCallback((contentsCov) => {
        const arr = [
            contentsIds.HOM_CONTENTS_COV_FLOOD_EXCESS_ADJUSTMENT,
            contentsIds.HOM_CONTENTS_COV_CONTENTS_ESCAPE_OF_WATER_EXCESS_ADJ,
            contentsIds.HOM_CONTENTS_COV_SUBSIDENCE_EXCESS_ADJ,
            contentsIds.HOM_CONTENTS_COV_SPECIFIED_VALUABLES_EXCESS_ADJUST,
            contentsIds.HOM_CONTENTS_COV_STORM_EXCESS_ADJUSTMENT,
            contentsIds.HOM_CONTENTS_COV_FIRE_EXCESS_ADJUSTMENT,
            contentsIds.HOM_CONTENTS_COV_THEFT_EXCESS_ADJUSTMENT,
            contentsIds.HOM_CONTENTS_COV_LEAKING_OIL_EXC_ADJ,
            contentsIds.HOM_CONTENTS_COV_FALLING_TREES_AND_BRANCHES_EXC_ADJ,
            contentsIds.HOM_CONTENTS_COV_FALLING_AERIALS_TURBINES_EXC_ADJ,
            contentsIds.HOM_CONTENTS_COV_CONTENT_COLLISION_VEH_EXCESS_ADJ,
            contentsIds.HOM_CONTENTS_COV_CONTENTS_VANDALS_EXCESS_ADJUST,
            contentsIds.HOM_CONTENTS_COV_RIOT_EXCESS_ADJ,
            contentsIds.HOM_CONTENTS_COV_AIRCRAFT_EXCESS_ADJ,
        ];

        return arr.reduce((acc, currentCovTerm) => {
            const result = getTermLimitValue(
                contentsCov,
                currentCovTerm,
                false
            );
            return result && +result.replace(/,/g, '') > 0 ? true : acc;
        }, false);
    }, [contentsIds, getTermLimitValue]);

    const contentsCovValue = useCallback((contentsCov) => {
        const accidental = contentsCov.terms.find((cov) => cov.publicID === contentsIds
            .HOM_CONTENTS_COV_ACCIDENTAL_LOSS_DAMAGE_EXCESS);
        if (submissionVM.value.isBespokeHomeInsurance_NFUM) {
            return accidental ? translator(messages.included) : translator(messages.excluded);
        }
        if (getTermValue(contentsCov, contentsIds.HOM_CONTENTS_COV_ACCIDENTAL_DAMAGE) === YES_KEY) {
            return translator(messages.added);
        }
        return translator(messages.notSelected);
    }, [contentsIds, submissionVM, translator, getTermValue]);

    const isBespokeAndCondition = useCallback((condition = true) => {
        const isBespoke = _.get(submissionVM.value, 'isBespokeHomeInsurance_NFUM');
        return isBespoke && condition;
    }, [submissionVM]);

    // eslint-disable-next-line max-len
    const generateHomePropertiesData = useCallback(() => { /* NOSONAR: GW Jutro specific convention */
        if (!homePropertiesData) {
            return {};
        }
        const homePropertiesOverrides = homePropertiesData.map((homeProperty, index) => {
            const contentsCov = homeProperty.coverages.coverages
                .find(((cov) => cov.publicID === contentsIds.HOM_CONTENTS_COV));
            const isContentsCov = !!contentsCov;
            const contentsAwayCov = homeProperty.coverages.coverages
                .find(((cov) => cov.publicID === contentsIds.HOM_CONTENTS_AWAY_COV));
            const isContentsAwayCov = !!contentsAwayCov;
            const buildingsCov = homeProperty.coverages.coverages
                .find(((cov) => cov.publicID === contentsIds.HOM_BUILDINGS_COV));
            const isBuildingsCov = !!buildingsCov;
            const getVoluntaryExcessTermValue = contentsCov?.terms?.find((term) => term.publicID === 'HOMContentsCovVoluntaryExcess').chosenTermValue;
            const getVoluntaryExcessOtherTermValue = (getVoluntaryExcessTermValue === 'Other')
                ? getTermLimitValue(contentsCov,
                    contentsIds.HOM_CONTENTS_COV_OTHER_VOLUNTARY_EXCESS)
                : getTermLimitValue(contentsCov,
                    contentsIds.HOM_CONTENTS_COV_VOLUNTARY_EXCESS);
            // eslint-disable-next-line max-len
            const InteriorDecCost = getTermLimitValue(contentsCov, contentsIds.TENANT_INTERIOR_COST);
            return {
                [`contentsHomePropertyTitle${index}`]: {
                    content: getPropertyTitle(homeProperty, !isBuildingsCov)
                },
                [`contentsNotSelected${index}`]: {
                    visible: !isContentsCov,
                    value: translator(messages.notSelected)
                },
                [`contentsSelectedContainer${index}`]: {
                    visible: isContentsCov
                },
                [`noOfBedrooms${index}`]: {
                    visible: !isBuildingsCov,
                    value: homeProperty.homhomePropertyDetails.numberOfBedrooms
                },
                [`contentsSumInsured${index}`]: {
                    visible: !isBespokeAndCondition(displayContentLimit(
                        contentsCov,
                        contentsIds.HOM_CONTENTS_COV_CONTENTS_LEVEL_LIMIT
                    )),
                    value: getContentLimitValue(
                        contentsCov,
                        contentsIds.HOM_CONTENTS_COV_CONTENTS_LEVEL_LIMIT
                    ),
                },
                [`contentsLevelLimit${index}`]: {
                    visible: !isBespokeAndCondition()
                        && displayContentLimit(
                            contentsCov,
                            contentsIds.HOM_CONTENTS_COV_CONTENTS_LEVEL_LIMIT
                        ),
                    value: getContentLimitValue(
                        contentsCov,
                        contentsIds.HOM_CONTENTS_COV_CONTENTS_LEVEL_LIMIT
                    )
                },
                [`contentsReplacementCost${index}`]: {
                    visible: isBespokeAndCondition(),
                    value: getContentLimitValue(
                        contentsCov,
                        contentsIds.HOM_CONTENTS_COV_CONTENTS_PERSO_BELONG_REPLACEMENT_COST
                    )
                },
                [`contentsAccidentalDamage${index}`]: {
                    value: contentsCovValue(contentsCov)
                },
                [`contentsAwayLimit${index}`]: {
                    visible: !isBespokeAndCondition(),
                    value: isContentsAwayCov && getTermValue(contentsAwayCov,
                        contentsIds.HOM_CONTENTS_AWAY_COV_REQ_FOR_UNSPEC_BELONGINGS) === YES_KEY
                        ? getTermLimitValue(
                            contentsAwayCov,
                            contentsIds.HOM_CONTENTS_AWAY_COV_UNSPC_BELONGINGS_REPL_COST
                        ) : translator(messages.notSelected)
                },
                [`unspecifiedBicyclesLimit${index}`]: {
                    visible: !isBespokeAndCondition(),
                    value: isContentsAwayCov && getTermValue(contentsAwayCov,
                        contentsIds.HOM_CONTENTS_AWAY_COV_REQ_FOR_UNSPEC_BELONGINGS) === YES_KEY
                        ? getTermLimitValue(
                            contentsAwayCov,
                            contentsIds.HOM_CONTENTS_AWAY_COV_UNSPC_PEDAL_CYCLES_LIMIT
                        ) : translator(messages.notSelected)
                },
                [`tenantsInteriorDecorationCov${index}`]: {
                    value: InteriorDecCost,
                    visible: getTermValue(contentsCov,
                        contentsIds.HOM_CONTENTS_COV_TENANTS_INTERIOR_DECORATION) === YES_KEY
                },
                [`confirmedContainer${index}`]: {
                    visible: !isBuildingsCov
                        && homeLine?.isPropertyNotForBusinessUse
                        && homeLine?.isPropertyNotImpactedByFloodAndSubsidence
                },
                [`youConfirmed${index}`]: {
                    visible: !isBuildingsCov
                },
                [`confirmationText2${index}`]: {
                    visible: isBespokeAndCondition(!bespokeSustainedDmg && !isBuildingsCov)
                        || !isBespokeAndCondition(isBuildingsCov)
                },
                [`confirmationText3${index}`]: {
                    visible: isBespokeAndCondition(!isBuildingsCov && bespokeSustainedDmg)
                },
                [`confirmationText4${index}`]: {
                    visible: isBespokeAndCondition(!bespokeFloodRisk && !isBuildingsCov)
                        || !isBespokeAndCondition(isBuildingsCov)
                },
                [`confirmationText5${index}`]: {
                    visible: isBespokeAndCondition(!isBuildingsCov && bespokeFloodRisk)
                },
                [`confirmationText6${index}`]: {
                    visible: !isBespokeAndCondition(isBuildingsCov)
                },
                [`confirmationText7${index}`]: {
                    visible: !isBespokeAndCondition(isBuildingsCov)
                },
                [`leakingAndFrozenWater${index}`]: {
                    value: getTermLimitValue(
                        contentsCov,
                        contentsIds.HOM_CONTENTS_COV_CONTENTS_ESCAPE_OF_WATER_EXCESS_TOTAL
                    )
                },
                [`subsidence${index}`]: {
                    value: getTermLimitValue(
                        contentsCov,
                        contentsIds.HOM_CONTENTS_COV_SUBSIDENCE_EXCESS
                    )
                },
                [`flood${index}`]: {
                    value: getTermLimitValue(
                        contentsCov,
                        contentsIds.HOM_CONTENTS_COV_FLOOD_TOTAL_EXCESS
                    )
                },
                [`voluntary${index}`]: {
                    value: isBespokeAndCondition()
                        ? getVoluntaryExcessOtherTermValue
                        : getTermLimitValue(
                            contentsCov,
                            contentsIds.HOM_CONTENTS_COV_VOLUNTARY_EXCESS
                        )
                },
                [`compulsaryAllClaims${index}`]: {
                    value: !isAnyAdjustmentsCovTermValue(contentsCov)
                        ? getTermLimitValue(
                            contentsCov,
                            contentsIds.HOM_CONTENTS_COV_ALL_OTHER_CLAIMS_EXCESS
                        ) : translator(messages.referToDocuments)
                }
            };
        });
        return Object.assign({}, ...homePropertiesOverrides);
    }, [homePropertiesData,
        getTermValue,
        contentsIds,
        translator,
        getPropertyTitle,
        getTermLimitValue,
        isAnyAdjustmentsCovTermValue,
        homeLine,
        bespokeFloodRisk,
        bespokeSustainedDmg,
        getContentLimitValue,
        isBespokeAndCondition,
        displayContentLimit
    ]);

    const overrideProps = {
        '@field': {
            readOnly: true,
            labelPosition: 'left',
            className: 'riskObjectProperty',
            labelClassName: 'riskObjectPropertyLabel'
        },
        contentsHomeProperties: {
            data: homePropertiesData
        },
        ...generateHomePropertiesData()
    };

    const resolvers = {
        resolveClassNameMap: styles
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            classNameMap={resolvers.resolveClassNameMap}
            overrideProps={overrideProps}
        />
    );
}

export default ContentsSummary;
