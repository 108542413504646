import React, {
    useCallback, useRef, useEffect, useState, useContext
} from 'react';
import moment from 'moment';
import _ from 'lodash';
import { TranslatorContext } from '@jutro/locale';
import { withAuthenticationContext, useAuthentication } from 'gw-digital-auth-react';
import { ViewModelForm } from 'gw-portals-viewmodel-react';
import { WizardPage, wizardProps } from 'gw-portals-wizard-react';
import { useDependencies } from 'gw-portals-dependency-react';
import { NfumAccordionHeader } from 'nfum-components-platform-react';
import {
    useLobs,
    useErrorHandler,
    useCallRetry,
    RENEWAL_STEPS
} from 'nfum-portals-utils-react';
import { useWizardActions } from 'nfum-portals-wizard-react';
import usePropertyTitle from '../../hooks/usePropertyTitle';
import CaravanSummary from './CaravanSummary/CaravanSummary';
import BuildingsSummary from './BuildingsSummary/BuildingsSummary';
import metadata from './RenewalSummaryPage.metadata.json5';
import styles from './RenewalSummaryPage.module.scss';
import messages from './RenewalSummaryPage.messages';
import commonMessages from '../../NGHRenewal.messages';
import ContentsSummary from './ContentsSummary/ContentsSummary';
import AdditionalCoversSummary from './AdditionalCoversSummary/AdditionalCoversSummary';
import PaymentScheduleSummary from './PaymentScheduleSummary/PaymentScheduleSummary';
import PolicyDetailsSummary from './PolicyDetailsSummary/PolicyDetailsSummary';
import CostBreakdownBox from './CostBreakdownBox/CostBreakdownBox';
import AnimalSummary from './AnimalSummary/AnimalSummary';
import TravelSummary from './TravelSummary/TravelSummary';
import PersonalAccidentSummary from './PersonalAccidentSummary/PersonalAccidentSummary';
import ValuablesSummary from './ValuablesSummary/ValuablesSummary';
import BicyclesSummary from './BicyclesSummary/BicyclesSummary';
import BespokeValuables from '../BespokeValuables/BespokeValuables';
import useTagManager from '../../hooks/useTagManager';
import FineArtsCollectionsSummary from './FineArtsCollectionsSummary/FineArtsCollectionsSummary';

function RenewalSummaryPage(props) { /* NOSONAR: pure declarative usage  */
    const {
        wizardData: submissionVM,
        updateWizardData
    } = props;
    const { returnToDashboard } = useWizardActions();
    const {
        isPersonalAccidentLine,
        isAnimalLine,
        isTravelLine,
        isCaravanLine,
        isBuildingsCov,
        isContentsCov,
        isBespokeJourney, // to use in R1.2 to resolve if you're within Bespoke journey
        isFineArtAndCollectionsCov,
        isValuablesCov
    } = useLobs(submissionVM);
    const { handleError } = useErrorHandler();
    const { authHeader } = useAuthentication();
    const { PolicyService } = useDependencies('PolicyService');
    const DOCUMENT_TIMEOUT_DURATION_SECONDS = 180;
    const translator = useContext(TranslatorContext);
    const [addressValidationInd, setAddressValidationInd] = useState(null);
    const [disableNext, setDisableNext] = useState(null);
    const [isCardOpen, setIsCardOpen] = useState(false);
    const paymentMethod = _.get(submissionVM.value, 'baseData.paymentMethod_NFUM');
    const { history } = props;
    const {
        pushFormStepInfo,
        pushFormStepErrorInfo,
        pushAccordionOpenInfo,
        pushFileDownloadInfo,
        pushLinkClickInfo
    } = useTagManager();
    const cardsIds = Object.freeze({
        PAYMENT_SCHEDULE_CARD: 'paymentScheduleCard',
        POLICY_DETAILS_CARD: 'policyDetailsCard',
        ADDITIONAL_COVERS_CARD: 'additionalCoversCard',
        BUILDINGS_CARD: 'buildingsInsuranceCard',
        CONTENTS_CARD: 'contentsInsuranceCard',
        CARAVAN_CARD: 'caravanCard',
        CAT_DOG_CARD: 'dogAndCatCard',
        TRAVEL_CARD: 'travelCard',
        PERSONAL_ACCIDENT_CARD: 'personalAccidentCard',
        VALUABLES: 'valuablesCard',
        BESPOKE_VALUABLES_CARD: 'bespokeValuablesCard',
        BICYCLES: 'bicyclesCard',
        FINEARTS: 'fineArtsCollectionsCard',
    });
    const [isConditionsExclusionsAvailable, setIsConditionsExclusionsAvailable] = useState(false);
    const documentsSectionRef = useRef();
    const scrollToBespokeValuablesSection = useRef();
    const isCash = _.get(submissionVM.value, 'baseData.paymentMethod_NFUM') === 'cash';
    const { getPropertyTitle } = usePropertyTitle();
    const homeScheduleItems = _.get(submissionVM, 'lobData.value.homeLine.coverables.homeProperties[0].coverages.schedules[0].scheduleItems');
    const producerDesc = _.get(submissionVM.value, 'baseData.producerDetails_NFUM.producerCodeDescription', '');
    const producerTele = _.get(submissionVM.value, 'baseData.producerDetails_NFUM.producerCodeTelephone', '');
    const isBespoke = _.get(submissionVM.value, 'baseData.producerDetails_NFUM.isBespoke', '');
    const showAgencyDetails = _.get(submissionVM.value, 'baseData.producerDetails_NFUM.showProducerDetails', '');
    const isMutualDirect = _.get(submissionVM.value, 'baseData.producerDetails_NFUM.isMutualDirect', '');

    useEffect(() => {
        const documentErrorInfoChannel = new BroadcastChannel('document_error');

        documentErrorInfoChannel.onmessage = () => {
            history.push('/service-unavailable');
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // #region GTM EVENTS
    useEffect(() => {
        pushFormStepInfo(submissionVM, RENEWAL_STEPS.SUMMARY);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Function to handle scrolling behavior
    const simulateAccordionClick = () => {
        setIsCardOpen(!isCardOpen);
    };
    const handleAccordionScroll = useCallback((cardId) => {
        const openAccordion = isCardOpen
            ? document.querySelector('.activeCard')
            : document.getElementById(cardId);
        if (openAccordion) {
            const header = document.getElementById('headerWrapper');
            const headerRect = header.getBoundingClientRect();
            const contentRect = openAccordion.getBoundingClientRect();
            const desiredScrollTop = contentRect.top - headerRect.height;
            window.scrollBy({
                top: desiredScrollTop,
                behavior: 'smooth'
            });
        }
    }, [isCardOpen]);

    useEffect(() => {
        handleAccordionScroll();
    }, [handleAccordionScroll]);

    const onUpdateOpenAccordion = useCallback((accordionCardsIds) => {
        const openCardId = accordionCardsIds && accordionCardsIds.length > 0
            ? accordionCardsIds[0] : null;
        switch (openCardId) {
            case cardsIds.PAYMENT_SCHEDULE_CARD:
                pushAccordionOpenInfo(translator(messages.paymentSchedule));
                handleAccordionScroll(cardsIds.PAYMENT_SCHEDULE_CARD);
                break;
            case cardsIds.POLICY_DETAILS_CARD:
                pushAccordionOpenInfo(translator(messages.policyDetails));
                handleAccordionScroll(cardsIds.POLICY_DETAILS_CARD);
                break;
            case cardsIds.ADDITIONAL_COVERS_CARD:
                pushAccordionOpenInfo(translator(messages.additionalCovers));
                handleAccordionScroll(cardsIds.ADDITIONAL_COVERS_CARD);
                break;
            case cardsIds.BUILDINGS_CARD:
                pushAccordionOpenInfo(translator(messages.buildings));
                handleAccordionScroll(cardsIds.BUILDINGS_CARD);
                break;
            case cardsIds.CONTENTS_CARD:
                pushAccordionOpenInfo(translator(messages.contents));
                handleAccordionScroll(cardsIds.CONTENTS_CARD);
                break;
            case cardsIds.CARAVAN_CARD:
                pushAccordionOpenInfo(translator(messages.caravan));
                handleAccordionScroll(cardsIds.CARAVAN_CARD);
                break;
            case cardsIds.CAT_DOG_CARD:
                pushAccordionOpenInfo(translator(messages.catDog));
                handleAccordionScroll(cardsIds.CAT_DOG_CARD);
                break;
            case cardsIds.TRAVEL_CARD:
                pushAccordionOpenInfo(translator(messages.travel));
                handleAccordionScroll(cardsIds.TRAVEL_CARD);
                break;
            case cardsIds.PERSONAL_ACCIDENT_CARD:
                pushAccordionOpenInfo(translator(messages.personalAccident));
                handleAccordionScroll(cardsIds.PERSONAL_ACCIDENT_CARD);
                break;
            case cardsIds.VALUABLES:
                pushAccordionOpenInfo(translator(messages.valuables));
                handleAccordionScroll(cardsIds.VALUABLES);
                break;
            case cardsIds.BESPOKE_VALUABLES_CARD:
                pushAccordionOpenInfo(translator(messages.valuables));
                handleAccordionScroll(cardsIds.BESPOKE_VALUABLES_CARD);
                break;
            case cardsIds.BICYCLES:
                pushAccordionOpenInfo(translator(messages.bicycles));
                handleAccordionScroll(cardsIds.BICYCLES);
                break;
            case cardsIds.FINEARTS:
                pushAccordionOpenInfo(translator(messages.fineArts));
                handleAccordionScroll(cardsIds.FINEARTS);
                break;
            default:
                // do nothing
        }
    // eslint-disable-next-line max-len
    }, [cardsIds.ADDITIONAL_COVERS_CARD, cardsIds.BESPOKE_VALUABLES_CARD, cardsIds.BICYCLES, cardsIds.BUILDINGS_CARD, cardsIds.CARAVAN_CARD, cardsIds.CAT_DOG_CARD, cardsIds.CONTENTS_CARD, cardsIds.FINEARTS, cardsIds.PAYMENT_SCHEDULE_CARD, cardsIds.PERSONAL_ACCIDENT_CARD, cardsIds.POLICY_DETAILS_CARD, cardsIds.TRAVEL_CARD, cardsIds.VALUABLES, handleAccordionScroll, pushAccordionOpenInfo, translator]);
    // #endregion GTM EVENTS

    useEffect(() => {
        const lobData = _.get(submissionVM.value, 'lobData');
        const jobId = _.get(submissionVM.value, 'jobID');
        const policyNumber = _.get(submissionVM.value, 'policyNumber');
        const res = Object.entries(lobData)
            .reduce((initValue, [, value]) => initValue
                || value?.isConditionsExclusionsAvailable, false);
        setIsConditionsExclusionsAvailable(res);
        const baseData = _.get(submissionVM.value, 'baseData');
        if (baseData.automaticRenewalOoption_NFUM && baseData.paymentMethod_NFUM === 'directdebit') {
            history.push(`/contact-us?jobid=${jobId}&policyNumber=${policyNumber}&showRenewalDesc=true`);
        }
    }, [submissionVM, history]);

    const coverages = _.get(submissionVM, 'lobData.value.homeLine.coverables.homeProperties[0].coverages.coverages');
    const contentsCov = coverages.find(((cov) => cov.publicID === 'HOMContentsCov'));
    const fineArtCovs = coverages.find((cov) => cov.publicID === 'HOMFineArtCollectionsCov');

    const fetchDocuments = useCallback(async () => {
        const docs = await PolicyService.getDocuments(
            [submissionVM.value.jobID],
            authHeader
        );
        return docs;
    }, [PolicyService, submissionVM.value.jobID, authHeader]);

    const {
        loading: isLoading,
        error: docError,
        data: documents
    } = useCallRetry(
        fetchDocuments,
        {
            retryTimes: 10,
            retryDelayInSeconds: 10,
            initialIsLoading: true,
            timeoutDurationSeconds: DOCUMENT_TIMEOUT_DURATION_SECONDS
        },
        []
    );

    const handleNext = useCallback(() => {
        return submissionVM;
    }, [submissionVM]);

    const handleAddressValidationChange = useCallback((isConfirmed) => {
        if (isConfirmed) {
            setAddressValidationInd(true);
            setDisableNext(true);
        } else {
            setAddressValidationInd(false);
            setDisableNext(false);
        }
    }, [setAddressValidationInd]);

    const renderAccordionHeader = (isOpen, title, openMessage) => {
        return (
            <NfumAccordionHeader
                isOpen={isOpen}
                title={title}
                openMessage={openMessage}
            />
        );
    };

    const onFindAgencyClicked = useCallback(() => {
        const url = 'https://www.nfumutual.co.uk/agent-offices/';
        pushLinkClickInfo(translator(messages.customMessageDocumentation1), url);
        window.open(url, '_blank');
    }, [pushLinkClickInfo, translator]);

    const renderValuableHeader = (isOpen) => {
        return renderAccordionHeader(
            isOpen,
            messages.valuables,
            messages.reviewCover
        );
    };
    const overrideProps = {
        policyDetailsList: {
            onUpdateAccordionStates: onUpdateOpenAccordion
        },
        [cardsIds.PAYMENT_SCHEDULE_CARD]: {
            visible: !isCash,
            renderHeader: (isOpen) => {
                return renderAccordionHeader(
                    isOpen,
                    messages.paymentSchedule,
                    messages.reviewDetails
                );
            }
        },
        [cardsIds.POLICY_DETAILS_CARD]: {
            renderHeader: (isOpen) => {
                return renderAccordionHeader(
                    isOpen,
                    messages.policyDetails,
                    messages.reviewDetails
                );
            }
        },
        [cardsIds.ADDITIONAL_COVERS_CARD]: {
            visible: isBuildingsCov || isContentsCov,
            renderHeader: (isOpen) => {
                return renderAccordionHeader(
                    isOpen,
                    messages.additionalCovers,
                    messages.reviewCover
                );
            }
        },
        [cardsIds.VALUABLES]: {
            visible: homeScheduleItems
                ?.filter((item) => !_.isEmpty(item.itemData.ArticleGeographicLimit))?.length > 0,
            renderHeader: renderValuableHeader
        },
        [cardsIds.BESPOKE_VALUABLES_CARD]: {
            visible: true,
            renderHeader: renderValuableHeader
        },
        [cardsIds.BICYCLES]: {
            visible: !isBespokeJourney && homeScheduleItems
                ?.filter((item) => _.isEmpty(item.itemData.ArticleGeographicLimit))?.length > 0,
            renderHeader: (isOpen) => {
                return renderAccordionHeader(
                    isOpen,
                    messages.bicycles,
                    messages.reviewCover
                );
            }
        },
        [cardsIds.BUILDINGS_CARD]: {
            visible: isBuildingsCov,
            renderHeader: (isOpen) => {
                return renderAccordionHeader(
                    isOpen,
                    messages.buildings,
                    messages.reviewCover
                );
            }
        },
        [cardsIds.CONTENTS_CARD]: {
            visible: isContentsCov,
            renderHeader: (isOpen) => {
                return renderAccordionHeader(
                    isOpen,
                    messages.contents,
                    messages.reviewCover
                );
            }
        },
        [cardsIds.TRAVEL_CARD]: {
            visible: isTravelLine,
            renderHeader: (isOpen) => {
                return renderAccordionHeader(
                    isOpen,
                    messages.travel,
                    messages.reviewCover
                );
            }
        },
        [cardsIds.CARAVAN_CARD]: {
            visible: isCaravanLine,
            renderHeader: (isOpen) => {
                return renderAccordionHeader(
                    isOpen,
                    messages.caravan,
                    messages.reviewCover
                );
            }
        },
        [cardsIds.CAT_DOG_CARD]: {
            visible: isAnimalLine,
            renderHeader: (isOpen) => {
                return renderAccordionHeader(
                    isOpen,
                    messages.catDog,
                    messages.reviewCover
                );
            }
        },
        [cardsIds.PERSONAL_ACCIDENT_CARD]: {
            visible: isPersonalAccidentLine,
            renderHeader: (isOpen) => {
                return renderAccordionHeader(
                    isOpen,
                    messages.personalAccident,
                    messages.reviewCover
                );
            }
        },
        [cardsIds.FINEARTS]: {
            visible: isBespokeJourney && isFineArtAndCollectionsCov,
            renderHeader: (isOpen) => {
                return renderAccordionHeader(
                    isOpen,
                    messages.fineArts,
                    messages.reviewCover
                );
            }
        },
        costBreakdownBox: {
            submissionVM
        },
        paymentScheduleSummary: {
            installmentDetails: _.get(submissionVM.value, 'installmentDetails_NFUM')
        },
        policyDetailsSummary: {
            submissionVM
        },
        buildingsSummary: {
            homePropertiesData: _.get(submissionVM, 'lobData.value.homeLine.coverables.homeProperties'),
            homeLine: _.get(submissionVM, 'lobData.value.homeLine'),
            submissionVM
        },
        fineArtsCollectionsSummary: {
            homePropertiesData: _.get(submissionVM, 'lobData.value.homeLine.coverables.homeProperties'),
            submissionVM
        },
        contentsSummary: {
            homePropertiesData: _.get(submissionVM, 'lobData.value.homeLine.coverables.homeProperties'),
            submissionVM
        },
        valuablesSummary: {
            homePropertiesData: _.get(submissionVM, 'lobData.value.homeLine.coverables.homeProperties')
        },
        bicyclesSummary: {
            homePropertiesData: _.get(submissionVM, 'lobData.value.homeLine.coverables.homeProperties')
        },
        additionalCoversSummary: {
            homePropertiesData: _.get(submissionVM, 'lobData.value.homeLine.coverables.homeProperties'),
            lexLineCoverages: _.get(submissionVM, 'lobData.value.lexLine.lineCoverages.coverages'),
            homeLineCoverages: _.get(submissionVM, 'lobData.value.homeLine.lineCoverages.coverages'),
            submissionVM
        },
        caravanSummary: {
            value: _.get(submissionVM, 'lobData.value.caravanLine.coverables.caravans')
        },
        animalSummary: {
            value: _.get(submissionVM, 'lobData.value.animalLine.coverables.animals')
        },
        travelSummary: {
            value: _.get(submissionVM, 'lobData.value.travelLine.coverables.travellers'),
            submissionVM
        },
        personalAccidentSummary: {
            value: _.get(submissionVM, 'lobData.value.personalAccidentLine.coverables.individuals')
        },
        yourQuoteDocumentation: {
            onClick: () => documentsSectionRef.current.scrollIntoView()
        },
        needToMakeAChange: {
            producerDesc: producerDesc,
            producerTele: producerTele,
            isBespoke: isBespoke,
            showAgencyDetails: showAgencyDetails,
            showCallTimings: isMutualDirect
        },
        needToMakeChangeRenewalDate: {
            content: moment(_.get(submissionVM.value, 'baseData.periodStartDate')).format('DD MMM YYYY')
        },
        needToMakeChangeInfoForDirectDebit: {
            visible: !isCash
        },
        needToMakeChangeInfoForCash: {
            visible: isCash
        },
        policyDetailsAnchor: {
            onClick: simulateAccordionClick,
            className: isCardOpen ? 'activeCard' : ''
        },
        paymentScheduleAnchor: {
            onClick: simulateAccordionClick,
            className: isCardOpen ? 'activeCard' : ''
        },
        buildingsInsuranceCardContainer: {
            onClick: simulateAccordionClick,
            className: isCardOpen ? 'activeCard' : ''
        },
        contentsInsuranceCardContainer: {
            onClick: simulateAccordionClick,
            className: isCardOpen ? 'activeCard' : '',
            visible: !!contentsCov
        },
        fineartAndCollectionCardContainer: {
            onClick: simulateAccordionClick,
            className: isCardOpen ? 'activeCard' : '',
            visible: !!fineArtCovs
        },
        additionalCoversCardContainer: {
            onClick: simulateAccordionClick,
            className: isCardOpen ? 'activeCard' : ''
        },
        yourDocumentsSectionAnchor: {
            ref: documentsSectionRef
        },
        riskBillingAddValidationInd: {
            onValueChange: handleAddressValidationChange,
            value: addressValidationInd
        },
        referralMessage: {
            description: translator(messages.addValidationReferalMessage1),
            description1: translator(messages.addValidationReferalMessage2),
            producerDesc: producerDesc,
            producerTele: producerTele,
            isBespoke: isBespoke,
            showAgencyDetails: showAgencyDetails
        },
        documentsList: {
            documents: documents,
            pushFileDownloadInfo
        },
        inYourQuoteDocumentation: {
            onClick: () => documentsSectionRef.current.scrollIntoView()
        },
        specialConditionsContainer: {
            visible: isConditionsExclusionsAvailable
        },
        policyScheduleLink: {
            onClick: () => documentsSectionRef.current.scrollIntoView()
        },
        referralMessageContainer: {
            visible: addressValidationInd === false
        },
        loader: {
            visible: isLoading,
            loaded: !isLoading
        },
        summaryComponent: {
            visible: !isLoading
        },
        billingvsRiskValidation: {
            visible: paymentMethod === 'cash'
        },
        confirmBillingAddressMessage: {
            value: _.get(submissionVM.value, 'baseData.billingAddress_NFUM.displayName'),
            readOnly: true
        },
        bespokeValuablesCardContainer: {
            visible: isBespokeJourney && isValuablesCov === true,
            onClick: simulateAccordionClick,
            className: isCardOpen ? 'activeCard' : '',
            ref: scrollToBespokeValuablesSection
        },
        customMessageDiv: {
            visible: homeScheduleItems?.length > 20
        },
        valuablesInfoContainer: {
            visible: homeScheduleItems?.length < 21
        },
        valuablesHomePropertyTitle: {
            content: getPropertyTitle(_.get(submissionVM, 'lobData.value.homeLine.coverables.homeProperties[0]')),
            visible: homeScheduleItems?.length < 21
        },
        bespokeValuables: {
            scheduleItems: homeScheduleItems,
            submissionVM,
            visible: homeScheduleItems?.length < 21
        },
        termsAndConditionsContainer: {
            isAutoRenewal: !isCash
        }
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveComponentsMap: {
            travelSummary: TravelSummary,
            personalAccidentSummary: PersonalAccidentSummary,
            animalSummary: AnimalSummary,
            caravanSummary: CaravanSummary,
            buildingsSummary: BuildingsSummary,
            contentsSummary: ContentsSummary,
            additionalCoversSummary: AdditionalCoversSummary,
            paymentScheduleSummary: PaymentScheduleSummary,
            policyDetailsSummary: PolicyDetailsSummary,
            costBreakdownBox: CostBreakdownBox,
            valuablesSummary: ValuablesSummary,
            bicyclesSummary: BicyclesSummary,
            bespokeValuables: BespokeValuables,
            fineArtsCollectionsSummary: FineArtsCollectionsSummary
        },
        resolveCallbackMap: {
            onScrollToDocumentsSection: () => documentsSectionRef.current.scrollIntoView(),
            onScrollToContactAgent: onFindAgencyClicked
        }
    };

    if (docError?.errorCode) {
        pushFormStepErrorInfo(submissionVM, RENEWAL_STEPS.SUMMARY, docError);
        handleError(docError, submissionVM.value.quoteID);
        return false;
    }

    return (
        <WizardPage
            onNext={handleNext}
            showNext={isCash && !isLoading}
            showCancel={false}
            showPrevious={false}
            showJumpBack={!isLoading}
            onJumpBack={() => returnToDashboard(translator(commonMessages.returnToDashboard))}
            nextLabel={commonMessages.renewPolicy}
            disableNext={!disableNext && paymentMethod === 'cash'}
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={submissionVM}
                onModelChange={updateWizardData}
                overrideProps={overrideProps}
                classNameMap={resolvers.resolveClassNameMap}
                componentMap={resolvers.resolveComponentsMap}
                callbackMap={resolvers.resolveCallbackMap}
            />
        </WizardPage>
    );
}

RenewalSummaryPage.propTypes = wizardProps;
export default withAuthenticationContext(RenewalSummaryPage);
