import { defineMessages } from 'react-intl';

export default defineMessages({
    directDebitTermsContainer: {
        id: 'nfum.platform.nfumTermsAndConditions.termsAndConditions',
        defaultMessage: 'Terms and conditions'
    },
    termsAndConditionsIntro: {
        id: 'nfum.platform.nfumTermsAndConditions.termsAndConditionsIntro',
        defaultMessage: 'It is important that you have read the above policy documentation carefully before you renew. If you do not understand anything contained in the policy documentation, please contact us to discuss.'
    },
    termsPoint1: {
        id: 'nfum.platform.nfumTermsAndConditions.termsPoint1',
        defaultMessage: '1) you agree to the terms and conditions contained in the above policy documentation;'
    },
    termsPoint2: {
        id: 'nfum.platform.nfumTermsAndConditions.termsPoint2',
        defaultMessage: '2) you agree to our '
    },
    termsPoint3: {
        id: 'nfum.platform.nfumTermsAndConditions.termsPoint3',
        defaultMessage: '3) the insurance cover you have chosen meets your needs;'
    },
    termsPoint4: {
        id: 'nfum.platform.nfumTermsAndConditions.termsPoint4',
        defaultMessage: '4) you understand that, in common with other insurers, your details will be exchanged with them and fraud prevention agencies. If fraud is detected, you could be refused certain services, finance or employment; and'
    },
    termsPoint5: {
        id: 'nfum.platform.nfumTermsAndConditions.termsPoint5',
        defaultMessage: '5) you understand that if any details you have given are false or inaccurate or, if we identify fraud, we may reject any claim you make, and your insurance cover could be cancelled.'
    },
    mutualityAndCharitable: {
        id: 'nfum.platform.nfumTermsAndConditions.mutualityAndCharitable',
        defaultMessage: 'Mutuality and Charitable Trust Assignment'
    },
    byClickingText: {
        id: 'nfum.platform.nfumTermsAndConditions.byClickingText',
        defaultMessage: 'By clicking the "Renew my policy" button below you are confirming:'
    },
    byClickingTextAutoRenewal: {
        id: 'nfum.platform.nfumTermsAndConditions.byClickingTextAutoRenewal',
        defaultMessage: 'By proceeding with your renewal you are confirming:'
    },
    forMoreInformation: {
        id: 'nfum.platform.nfumTermsAndConditions.forMoreInformation',
        defaultMessage: 'For more information about how NFU Mutual processes your personal information, and your rights, please see our '
    },
    privacyPolicy: {
        id: 'nfum.platform.nfumTermsAndConditions.privacyPolicy',
        defaultMessage: 'privacy policy'
    },
});
