import React, { useEffect, useContext } from 'react';
import classNames from 'classnames';
import { TranslatorContext } from '@jutro/locale';
import styles from './NfumSpinner.module.scss';
import messages from './NfumSpinner.messages';

function NfumSpinner({ isOpen }) {
    const translator = useContext(TranslatorContext);

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, [isOpen]);

    return (
        <div
            className={classNames({
                [styles.spinnerContainer]: true,
                [styles.overlay]: true
            })}
            role="alert"
            aria-busy="true"
        >
            <span className={styles.spinner} />
            <p className={styles.loaderText}>
                { translator(messages.thankYou) }
                <br />
                { translator(messages.loadingYourDetails) }
            </p>
        </div>
    );
}

export default NfumSpinner;
