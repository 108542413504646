import { useState, useEffect } from 'react';

function usePhoneNumberObserver(observerReinitializerProperty, observerClassName) {
    const [infinityObserverInitialized, setInfinityObserverInitialized] = useState(false);
    const [infinityObserver, setInfinityObserver] = useState();
    const [infinityPhoneNumber, setInfinityPhoneNumber] = useState('');

    useEffect(() => {
        setInfinityObserverInitialized(false);
    }, [observerReinitializerProperty]);

    function observePhoneNumberChanges() {
        const inifinityTrackPhoneNumber = document.querySelector(`.${observerClassName}`);

        if (!inifinityTrackPhoneNumber) {
            return;
        }

        const observer = new MutationObserver((mutationsList) => {
            mutationsList.forEach(() => {
                const newPhoneNumber = inifinityTrackPhoneNumber.textContent;
                if (newPhoneNumber) {
                    setInfinityPhoneNumber(newPhoneNumber);
                }
            });
        });

        observer.observe(
            inifinityTrackPhoneNumber,
            { characterData: true, childList: true, subtree: true }
        );

        setInfinityObserver(observer);
    }

    function doTryToObserve() {
        if (infinityObserverInitialized !== true) {
            infinityObserver?.disconnect();
            setInfinityObserverInitialized(true);
            observePhoneNumberChanges();
        }
    }

    return {
        infinityPhoneNumber,
        doTryToObserve
    };
}

export default usePhoneNumberObserver;
